import React, { useEffect, useRef, useState } from 'react';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import { useSpring, animated } from 'react-spring';
import './Gallery.css';

function Gallery({ images, title }) {
  const [selectedImg, setSelectedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const galleryRef = useRef(null);

  useEffect(() => {
    const msnry = new Masonry(galleryRef.current, {
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      percentPosition: true
    });

    // Layout Masonry again after each image loads
    imagesLoaded(galleryRef.current).on('progress', () => {
      msnry.layout();
    });
  }, []);

  const openModal = (imgSrc, index) => {
    setSelectedImg(imgSrc.replace('_thumbnails', ''));
    setCurrentIndex(index);
  };

  const closeModal = () => {
    setSelectedImg(null);
    setCurrentIndex(null);
  };

  const goToNext = (event) => {
    event.stopPropagation(); // Prevent event from bubbling up

    const newIndex = (currentIndex + 1) % images.length;
    if (images[newIndex]) {
      openModal(images[newIndex].src, newIndex);
    }
  };

  const goToPrevious = (event) => {
    event.stopPropagation(); // Prevent event from bubbling up

    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = images.length - 1;
    }

    if (images[newIndex]) {
      openModal(images[newIndex].src, newIndex);
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowRight') {
        goToNext(e);
      } else if (e.key === 'ArrowLeft') {
        goToPrevious(e);
      }
    };

    if (selectedImg) {
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [selectedImg, goToNext, goToPrevious]); // Dependencies

  const [touchStart, setTouchStart] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    if (!touchStart) {
      return;
    }
  
    const touchEnd = e.changedTouches[0].clientX;
    const touchDifference = touchStart - touchEnd;
  
    // Threshold can be adjusted for sensitivity
    const swipeThreshold = 50;
  
    // Reset touchStart for the next swipe
    setTouchStart(null);
  
    // Decide to navigate to the next or previous image based on swipe direction
    if (Math.abs(touchDifference) > swipeThreshold) {
      if (touchDifference > 0) {
        // Swiped left, show next image
        goToNext(e);
      } else {
        // Swiped right, show previous image
        goToPrevious(e);
      }
    }
  
    // Reset swipe position if threshold not met
    setSwipe(0); 
  };
  

  const [swipe, setSwipe] = useState(0);
  const swipeAnimation = useSpring({ transform: `translateX(${swipe}px)` });

  const handleTouchMove = (e) => {
    if (!touchStart) {
      return;
    }
    const currentPosition = e.touches[0].clientX;
    setSwipe(currentPosition - touchStart);
  };

  return (
    <>
      <h2>{title}</h2>
      <div className="grid" ref={galleryRef}>
        <div className="grid-sizer"></div>
        {images.map((image, index) => (
          <div key={index} className="grid-item" onClick={() => openModal(image.src, index)}>
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </div>
      {selectedImg && (
        <div
          className="modal"
          onClick={closeModal}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <span className="modal-close" onClick={closeModal}>&times;</span>
          <button className="modal-prev" onClick={(e) => goToPrevious(e)}>&lt;</button>
          <animated.img
            src={selectedImg}
            alt="Full size"
            className="modal-content"
            style={swipeAnimation}
          />
          <button className="modal-next" onClick={(e) => goToNext(e)}>&gt;</button>
        </div>
      )}
    </>
  );
}

export default Gallery;
