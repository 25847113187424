const imageData = {
  "all_colors": {
    title: "All Colors",
    thumbnails: [
      { src: "images/all_colors_thumbnails/0cc44718cd2e25b4e888ce7dce3146ef_aba15efa64d043ca4d9ae9d979ae7e86_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/2012_02_05 11_50_18.00_76a9fe4d-7716-449b-9a8b-91c5f774b342.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/2013-07-27_15_53_40.530000_662c33d7cb2f3bf9fa61e625bba19ea330d1e51eff563d655715482d94133805_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/2013_09_28 15_03_26.75_8a7e9362-074e-4098-bd6c-99981fa236af.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/2014_12_30 18_25_34.40_1882fb26-aaed-4dbf-98a7-0a71a85e0034.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/2015_04_04 08_36_28.00_58662894-b4fc-49cf-a405-f284cdef3c86.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/2017_09_17 10_01_57.00_672c59c6-952e-4b0b-b143-e2bf6f2359df.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/2020_04_21 05_31_19.00_f2aeaefd-c027-42c4-9ff7-15a167a30b17.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/2020_08_22 18_02_30.97_21d639ab-dec2-4a00-9065-12a41aa2d0a7.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/2020_08_22 18_08_53.00_f4ef6815-d8f5-4d98-b984-75e4332bec04.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/20220613121232_VLDX2138.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/20230417_101551.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/Recovered_cr3_file(1069)_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/Recovered_cr3_file(792)_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/Recovered_cr3_file(962)_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VDX41689.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX0141_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX0154.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX0591_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX0653.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX0679.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX0700_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX1219.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX1220.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX1250.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX1370.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX1379.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX1598.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX1608.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX1639.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX1685.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX1718.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX1816.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX2002.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX2004.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX2022.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX2037.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX2140.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX2202.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX2468_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX2601_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX2681_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX2868.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX2880.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX3116.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX3504.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX3552.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX3620.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX5071.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX5668_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX5726.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX5809.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX6342.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX6346.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX6351.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX6504.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX6505.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX6633.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX6670.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX6772.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX6772_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX6787_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX6985.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX7027.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX7036.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX7204.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX7325.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX7334.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX7344.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX7365.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX7368.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX7373.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX7485.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX7565.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8015_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8020.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8021.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8297.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8299.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8372.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8400_DxO_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8562.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8586.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8630.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8644.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8845.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8955.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX8984.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX9201.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX9362.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX9408.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX9567_DxO.jpg", alt: "Description of all_colors_thumbnails image" },
      { src: "images/all_colors_thumbnails/VLDX9574.jpg", alt: "Description of all_colors_thumbnails image" },
    ],
  },
  "amarillo": {
    title: "AMARILLO (VERANO EN PANAMA)",
    thumbnails: [
      { src: "images/amarillo_thumbnails/2012_02_05 11_50_18.00_76a9fe4d-7716-449b-9a8b-91c5f774b342.jpg", alt: "Description of amarillo_thumbnails image" },
      { src: "images/amarillo_thumbnails/2017_09_17 10_01_57.00_672c59c6-952e-4b0b-b143-e2bf6f2359df.jpg", alt: "Description of amarillo_thumbnails image" },
      { src: "images/amarillo_thumbnails/VLDX6772.jpg", alt: "Description of amarillo_thumbnails image" },
      { src: "images/amarillo_thumbnails/VLDX6772_DxO.jpg", alt: "Description of amarillo_thumbnails image" },
      { src: "images/amarillo_thumbnails/VLDX6787.jpg", alt: "Description of amarillo_thumbnails image" },
      { src: "images/amarillo_thumbnails/VLDX6985.jpg", alt: "Description of amarillo_thumbnails image" },
      { src: "images/amarillo_thumbnails/VLDX6985_DxO.jpg", alt: "Description of amarillo_thumbnails image" },
      { src: "images/amarillo_thumbnails/VLDX7485.jpg", alt: "Description of amarillo_thumbnails image" },
    ],
  },
  "blue": {
    title: "HORA AZUL (EL COLOR DEL DIA)",
    thumbnails: [
      { src: "images/blue_thumbnails/0cc44718cd2e25b4e888ce7dce3146ef_aba15efa64d043ca4d9ae9d979ae7e86_DxO.jpg", alt: "Description of blue_thumbnails image" },
      { src: "images/blue_thumbnails/VLDX1718.jpg", alt: "Description of blue_thumbnails image" },
      { src: "images/blue_thumbnails/VLDX3116.jpg", alt: "Description of blue_thumbnails image" },
      { src: "images/blue_thumbnails/VLDX3504.jpg", alt: "Description of blue_thumbnails image" },
      { src: "images/blue_thumbnails/VLDX5809.jpg", alt: "Description of blue_thumbnails image" },
      { src: "images/blue_thumbnails/VLDX7204.jpg", alt: "Description of blue_thumbnails image" },
      { src: "images/blue_thumbnails/VLDX8984.jpg", alt: "Description of blue_thumbnails image" },
      { src: "images/blue_thumbnails/VLDX9567.jpg", alt: "Description of blue_thumbnails image" },
      { src: "images/blue_thumbnails/VLDX9574.jpg", alt: "Description of blue_thumbnails image" },
    ],
  },
  "bw": {
    title: "BLANCO Y NEGRO (ARQUITECTURA Y CIUDAD)",
    thumbnails: [
      { src: "images/bw_thumbnails/Recovered_cr3_file(1069)_DxO.jpg", alt: "Description of bw_thumbnails image" },
      { src: "images/bw_thumbnails/Recovered_cr3_file(792)_DxO.jpg", alt: "Description of bw_thumbnails image" },
      { src: "images/bw_thumbnails/Recovered_cr3_file(962)_DxO.jpg", alt: "Description of bw_thumbnails image" },
      { src: "images/bw_thumbnails/VLDX0141_DxO.jpg", alt: "Description of bw_thumbnails image" },
      { src: "images/bw_thumbnails/VLDX0591_DxO.jpg", alt: "Description of bw_thumbnails image" },
      { src: "images/bw_thumbnails/VLDX0700_DxO.jpg", alt: "Description of bw_thumbnails image" },
      { src: "images/bw_thumbnails/VLDX2468_DxO.jpg", alt: "Description of bw_thumbnails image" },
      { src: "images/bw_thumbnails/VLDX2601_DxO.jpg", alt: "Description of bw_thumbnails image" },
      { src: "images/bw_thumbnails/VLDX2681_DxO.jpg", alt: "Description of bw_thumbnails image" },
      { src: "images/bw_thumbnails/VLDX8400_DxO_DxO.jpg", alt: "Description of bw_thumbnails image" },
    ],
  },
  "featured": {
    title: "Featured",
    thumbnails: [
      { src: "images/featured_thumbnails/0cc44718cd2e25b4e888ce7dce3146ef_aba15efa64d043ca4d9ae9d979ae7e86_DxO.jpg", alt: "Description of featured_thumbnails image" },
      { src: "images/featured_thumbnails/2015_06_18 07_53_00.00_a89f1c45-de25-46ca-8375-1b7cbba7c822.jpg", alt: "Description of featured_thumbnails image" },
      { src: "images/featured_thumbnails/2020_04_21 05_31_19.00_f2aeaefd-c027-42c4-9ff7-15a167a30b17.jpg", alt: "Description of featured_thumbnails image" },
      { src: "images/featured_thumbnails/2020_08_22 18_02_30.97_21d639ab-dec2-4a00-9065-12a41aa2d0a7.jpg", alt: "Description of featured_thumbnails image" },
      { src: "images/featured_thumbnails/f521f71c21dfb076ebb9cbc1fad56a03_15ae8619fceeb9a3a0b9ea9a2ca7c9f5_DxO.jpg", alt: "Description of featured_thumbnails image" },
      { src: "images/featured_thumbnails/Recovered_cr3_file(792)_DxO.jpg", alt: "Description of featured_thumbnails image" },
      { src: "images/featured_thumbnails/VLDX1370.jpg", alt: "Description of featured_thumbnails image" },
      { src: "images/featured_thumbnails/VLDX3620.jpg", alt: "Description of featured_thumbnails image" },
      { src: "images/featured_thumbnails/VLDX7204.jpg", alt: "Description of featured_thumbnails image" },
      { src: "images/featured_thumbnails/VLDX7365.jpg", alt: "Description of featured_thumbnails image" },
      { src: "images/featured_thumbnails/VLDX8644.jpg", alt: "Description of featured_thumbnails image" },
    ],
  },
  "golden": {
    title: "GOLDEN HOUR (ATARDECERES)",
    thumbnails: [
      { src: "images/golden_thumbnails/2014_12_30 18_25_34.40_1882fb26-aaed-4dbf-98a7-0a71a85e0034.jpg", alt: "Description of golden_thumbnails image" },
      { src: "images/golden_thumbnails/2020_08_22 18_02_30.97_21d639ab-dec2-4a00-9065-12a41aa2d0a7.jpg", alt: "Description of golden_thumbnails image" },
      { src: "images/golden_thumbnails/VLDX5668_DxO.jpg", alt: "Description of golden_thumbnails image" },
      { src: "images/golden_thumbnails/VLDX7027.jpg", alt: "Description of golden_thumbnails image" },
      { src: "images/golden_thumbnails/VLDX7325.jpg", alt: "Description of golden_thumbnails image" },
      { src: "images/golden_thumbnails/VLDX8015.jpg", alt: "Description of golden_thumbnails image" },
      { src: "images/golden_thumbnails/VLDX8297.jpg", alt: "Description of golden_thumbnails image" },
      { src: "images/golden_thumbnails/VLDX8562.jpg", alt: "Description of golden_thumbnails image" },
    ],
  },
  "green": {
    title: "VERDE: ESTACION LLUVIOSA",
    thumbnails: [
      { src: "images/green_thumbnails/2013-07-27_15_53_40.530000_662c33d7cb2f3bf9fa61e625bba19ea330d1e51eff563d655715482d94133805_DxO.jpg", alt: "Description of green_thumbnails image" },
      { src: "images/green_thumbnails/2015_04_04 08_36_28.00_58662894-b4fc-49cf-a405-f284cdef3c86.jpg", alt: "Description of green_thumbnails image" },
      { src: "images/green_thumbnails/VLDX1608.jpg", alt: "Description of green_thumbnails image" },
      { src: "images/green_thumbnails/VLDX2868.jpg", alt: "Description of green_thumbnails image" },
      { src: "images/green_thumbnails/VLDX3620.jpg", alt: "Description of green_thumbnails image" },
      { src: "images/green_thumbnails/VLDX9408.jpg", alt: "Description of green_thumbnails image" },
    ],
  },
  "pink": {
    title: "ROSA: AMANECER EN EL PACIFICO",
    thumbnails: [
      { src: "images/pink_thumbnails/2020_04_21 05_31_19.00_f2aeaefd-c027-42c4-9ff7-15a167a30b17.jpg", alt: "Description of pink_thumbnails image" },
      { src: "images/pink_thumbnails/VLDX1219.jpg", alt: "Description of pink_thumbnails image" },
      { src: "images/pink_thumbnails/VLDX1250.jpg", alt: "Description of pink_thumbnails image" },
      { src: "images/pink_thumbnails/VLDX1370.jpg", alt: "Description of pink_thumbnails image" },
      { src: "images/pink_thumbnails/VLDX1639.jpg", alt: "Description of pink_thumbnails image" },
      { src: "images/pink_thumbnails/VLDX1685.jpg", alt: "Description of pink_thumbnails image" },
      { src: "images/pink_thumbnails/VLDX1816.jpg", alt: "Description of pink_thumbnails image" },
      { src: "images/pink_thumbnails/VLDX2022.jpg", alt: "Description of pink_thumbnails image" },
      { src: "images/pink_thumbnails/VLDX3552.jpg", alt: "Description of pink_thumbnails image" },
    ],
  },
};

export default imageData;