import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Gallery from './components/Gallery';
import flickrLogo from './assets/flickr-svgrepo-com.svg';
import emailLogo from './assets/mail-svgrepo-com.svg';
import twitterLogo from './assets/twitter-x-seeklogo.com-4.svg';
import instagramLogo from './assets/instagram-svgrepo-com.svg';
import px500Logo from './assets/500px-logo-svgrepo-com.svg';
import pinterestLogo from './assets/pinterest-180-svgrepo-com.svg';
import imageData from './constImages';

function App() {
  // State to manage the current gallery category
  const [currentGallery, setCurrentGallery] = useState('featured');

  useEffect(() => {
    const footer = document.querySelector('.App-footer'); // Select the footer by its class

    const onScroll = () => {
      const fromBottom = document.body.scrollHeight - window.scrollY - window.innerHeight;

      // Check if the user is at the bottom of the page
      if (fromBottom <= 0) {
        // If at the bottom, show the footer
        footer.style.display = 'block';
      } else {
        // If not at the bottom, hide the footer
        footer.style.display = 'none';
      }
    };

    // Attach the event listener
    window.addEventListener('scroll', onScroll);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const [showFooter, setShowFooter] = useState(false);

  // Effect for handling footer visibility
  useEffect(() => {
    // Function to check and toggle footer visibility
    const checkFooterVisibility = () => {
      const contentHeight = document.documentElement.scrollHeight;
      const viewportHeight = window.innerHeight;
      const scrollPosition = window.scrollY + window.innerHeight;
      const isContentShorter = contentHeight < viewportHeight;
      const isAtBottom = contentHeight <= scrollPosition;

      setShowFooter(isAtBottom || isContentShorter);
    };

    // Check immediately in case the gallery is short
    checkFooterVisibility();

    // Add event listeners
    window.addEventListener('scroll', checkFooterVisibility);
    window.addEventListener('resize', checkFooterVisibility);

    // Clean up event listeners
    return () => {
      window.removeEventListener('scroll', checkFooterVisibility);
      window.removeEventListener('resize', checkFooterVisibility);
    };
  }, [currentGallery]); // Add currentGallery as a dependency


  // Function to update the gallery based on the category selected
  const updateGallery = (category) => {
    setCurrentGallery(category);
  };

  // Function to get images based on the category
  const getImagesForCategory = (category) => {
    // Check if the category is 'featured', if so, use 'featured_thumbnails'
    // const categoryName = category === 'featured' ? 'featured_thumbnails' : `${category}_thumbnails`;
    return imageData[category]?.thumbnails || [];
  };

  // Get the title for the current gallery category
  const currentGalleryTitle = imageData[currentGallery]?.title || 'Gallery';

  return (
    <div className="App">
      <header className="App-header">
        <a href="https://ladimi.com" target="_blank" rel="noopener noreferrer">
          <img src={logo} className="App-logo" alt="logo" />
        </a>
        <a href="https://ladimi.com" target="_blank" rel="noopener noreferrer" className="App-name">
          <h1>ladimi.com</h1>
        </a>
      </header>
      <main>
        <section className="contact">
          <div className="contact-info">
            <a href="https://www.flickr.com/photos/vladox/" target="_blank" rel="noopener noreferrer">
              <img src={flickrLogo} alt="Flickr" />
            </a>
            <a href="mailto:vlad@ladimi.com">
              <img src={emailLogo} alt="Email" />
            </a>
            <a href="https://twitter.com/vlad0x" target="_blank" rel="noopener noreferrer">
              <img src={twitterLogo} alt="Twitter" />
            </a>
            <a href="https://www.instagram.com/vlad0x/" target="_blank" rel="noopener noreferrer">
              <img src={instagramLogo} alt="Instagram" />
            </a>
            <a href="https://500px.com/p/vlad0x" target="_blank" rel="noopener noreferrer">
              <img src={px500Logo} alt="500px" />
            </a>
            <a href="https://www.pinterest.com/vlad0x/" target="_blank" rel="noopener noreferrer">
              <img src={pinterestLogo} alt="Pinterest" />
            </a>
          </div>
        </section>
        <section>
          <section className="about">
            <h2>COLORES SOMBRAS Y LUZ</h2>
          </section>
          <section className="gallery-links">
            {/* Use buttons if these are not navigating to a new page */}
            <button
              onClick={() => updateGallery('featured')}
              className={`gallery-link ${currentGallery === 'featured' ? 'active' : ''}`}
            >
              Featured
            </button>
            <button onClick={() => updateGallery('amarillo')} className="gallery-link">Amarillo</button>
            <button onClick={() => updateGallery('golden')} className="gallery-link">Golden</button>
            <button onClick={() => updateGallery('blue')} className="gallery-link">Blue Hour</button>
            <button onClick={() => updateGallery('green')} className="gallery-link">Green</button>
            <button onClick={() => updateGallery('pink')} className="gallery-link">Pink</button>
            <button onClick={() => updateGallery('bw')} className="gallery-link">Black and White</button>
            <button onClick={() => updateGallery('all_colors')} className="gallery-link">All Colors</button>
          </section>
        </section>
        <section className="gallery">
          {/* Use the currentGallery as key to force re-render when it changes */}
          <Gallery
            key={currentGallery}
            images={getImagesForCategory(currentGallery)}
            title={currentGalleryTitle} // Pass the title as a prop
          />
        </section>
      </main>
      {/* Conditionally render the footer based on showFooter state */}
      <footer className="App-footer" style={{ display: showFooter ? 'block' : 'none' }}>
        &copy; 2023 Vladimir Cuevas. All rights reserved.
      </footer>
    </div>
  );
}

export default App;
